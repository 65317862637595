import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import sortBy from 'lodash/sortBy'
import Helmet from 'react-helmet'
import config from '../utils/siteConfig'
import PostList from '../components/PostList'
import PostListItem from '../components/PostListItem'
import PostExcerpt from '../components/PostExcerpt'
import PaginationLinks from '../components/PaginationLinks'
import Heading from '../components/Heading'

const TagTemplate = ({ data, pageContext }) => {
  const { title, slug } = pageContext.additionalContext

  const { group, index, pageCount } = pageContext;
  const previousUrl = index - 1 == 1 ? "/" : "/" + (index - 1).toString() + "/" ;
  const nextUrl = "/" + (index + 1).toString() + "/" ;
  const indexSlug = index === 1 ? "" : index.toString() + "/"
  return (
    <Layout>
      <Helmet>
        <title>{`Tag: ${title} | ${config.siteTitle}`}</title>
        <meta
          property="og:title"
          content={`Tag: ${title} - ${config.siteTitle}`}
        />
        <meta property="og:url" content={`${config.siteUrl}/tag/${slug}/${indexSlug}`} />
      </Helmet>

      <div>
        <Heading.H1>Tag: {title}</Heading.H1>
        {group.map( post => (
          <PostListItem
            key={post.id}
            slug={post.slug}
            image={post.heroImage}
            title={post.title}
            subTitle={post.subTitle}
            date={post.publishDate}
            dateTime={post.publishDateISO}
            tags={post.tags}
            excerpt={post.body}
            author={post.author.authorName}
            avatar={post.author.avatar}
          />
        ))}
        <PaginationLinks index={index} pageCount={pageCount} previousUrl={previousUrl} nextUrl={nextUrl} />
      </div>
    </Layout>
  )
}

export default TagTemplate
